import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDesc = data.site.siteMetadata.description

  return (
    <Layout title={siteTitle} description={siteDesc}>
      <SEO
        title="O mnie"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h1 className="post-content-title">
            <span>
            </span>
          </h1>
          <p class="post-content-excerpt" id="">
            Odkąd pamiętam lubiłam piec i dzielić się ciastami z innymi.
            Najbardziej mnie cieszy jak widzę uśmiechnięte buzie i prośby o
            dokładki. Piekę by sprawiać innym radość. Na codzień mieszkam w
            Warszawie i pracuje w korporacji, a w każdy weekend staram się
            znaleźć kilka godzin na wypróbowanie nowego przepisu, czy pomysłu.
            To mój sposób na relaks. Inspiracje do wypieków biorę z książek
            kulinarnych, podróży, programów cukierniczych (God bless the
            Zumbo!), a także z chęci testowania różnych połączeń smaków i
            tekstur. Wierzę w jednorożce, karmę i w to, że magia dzieje się
            właśnie tam, gdzie dzielimy się pysznym jedzeniem i wypiekami z
            rodziną i przyjaciółmi.
          </p>
          <figure className="kg-card kg-image-card">
            <Img
              fluid={data.profilePicture.childImageSharp.fluid}
              className="kg-image"
            />
            <figcaption />
          </figure>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    profilePicture: file(relativePath: { eq: "ciastologia-o-mnie.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
